// 字典
export default {
    province: [
        {
            "label": "全国",
            "value": 100,
            "sort": 0,
        },
        {
            "label": "北京",
            "value": 110000,
            "sort": 1,
        },
        {
            "label": "天津",
            "value": 120000,
            "sort": 2,
        },
        {
            "label": "上海",
            "value": 310000,
            "sort": 3,
        },
        {
            "label": "重庆",
            "value": 500000,
            "sort": 4,
        },
        {
            "label": "河北",
            "value": 130000,
            "sort": 5,
        },
        {
            "label": "河南",
            "value": 410000,
            "sort": 6,
        },
        {
            "label": "云南",
            "value": 530000,
            "sort": 7,
        },
        {
            "label": "辽宁",
            "value": 210000,
            "sort": 8,
        },
        {
            "label": "黑龙江",
            "value": 230000,
            "sort": 9,
        },
        {
            "label": "湖南",
            "value": 430000,
            "sort": 10,
        },
        {
            "label": "安徽",
            "value": 340000,
            "sort": 11,
        },
        {
            "label": "山东",
            "value": 370000,
            "sort": 12,
        },
        {
            "label": "新疆",
            "value": 650000,
            "sort": 13,
        },
        {
            "label": "江苏",
            "value": 320000,
            "sort": 14,
        },
        {
            "label": "浙江",
            "value": 330000,
            "sort": 15,
        },
        {
            "label": "江西",
            "value": 360000,
            "sort": 16,
        },
        {
            "label": "湖北",
            "value": 420000,
            "sort": 17,
        },
        {
            "label": "广西",
            "value": 450000,
            "sort": 18,
        },
        {
            "label": "甘肃",
            "value": 620000,
            "sort": 19,
        },
        {
            "label": "山西",
            "value": 140000,
            "sort": 20,
        },
        {
            "label": "内蒙古",
            "value": 150000,
            "sort": 21,
        },
        {
            "label": "陕西",
            "value": 610000,
            "sort": 22,
        },
        {
            "label": "吉林",
            "value": 220000,
            "sort": 23,
        },
        {
            "label": "福建",
            "value": 350000,
            "sort": 24,
        },
        {
            "label": "贵州",
            "value": 520000,
            "sort": 25,
        },
        {
            "label": "广东",
            "value": 440000,
            "sort": 26,
        },
        {
            "label": "青海",
            "value": 630000,
            "sort": 27,
        },
        {
            "label": "西藏",
            "value": 540000,
            "sort": 28,
        },
        {
            "label": "四川",
            "value": 510000,
            "sort": 29,
        },
        {
            "label": "宁夏",
            "value": 640000,
            "sort": 30,
        },
        {
            "label": "海南",
            "value": 460000,
            "sort": 31,
        }
    ],
    operator: [
        {
            "label": "移动",
            "value": 10,
        },
        {
            "label": "联通",
            "value": 20,
        },
        {
            "label": "电信",
            "value": 30,
        },
        {
            "label": "移动-联通",
            "value": 40,
        },
        {
            "label": "移动-电信",
            "value": 50,
        },
        {
            "label": "联通-电信",
            "value": 60,
        },
        {
            "label": "三网",
            "value": 70,
        }
    ],
    sendState: [
        {
            "label": "待审核",
            "value": 1,
        },
        {
            "label": "拦截-审核失败",
            "value": 10,
        },
        {
            "label": "拦截-频次超限",
            "value": 12,
        },
        {
            "label": "拦截-时间限制",
            "value": 13,
        },
        {
            "label": "拦截-关键字策略",
            "value": 14,
        },
        {
            "label": "拦截-黑名单",
            "value": 11,
        },
        {
            "label": "待发送",
            "value": 240,
        },
        {
            "label": "已提交-未知",
            "value": 224,
        },
        {
            "label": "发送成功",
            "value": 128,
        },
        {
            "label": "发送失败",
            "value": 192,
        },
        {
            "label": "部分成功",
            "value": 241,
        }
    ],
    reportStatus: [
        {
            "label": "失败",
            "value": 255,
        },
        {
            "label": "成功",
            "value": 10,
        },
        {
            "label": "未知",
            "value": 0,
        },
        {
            "label": "部分成功",
            "value": 20,
        }
    ],
    billType: [
        {
            "label": "成功计费",
            "value": 100,
        },
        {
            "label": "提交计费",
            "value": 200,
        },
        {
            "label": "成功+未知",
            "value": 300,
        }
    ],
    payType: [
        {
            "label": "预付",
            "value": 1000,
        },
        {
            "label": "后付",
            "value": 2000,
        }
    ],
    enableStatus: [
        {
            "label": "启用",
            "value": 100,
        },
        {
            "label": "停用",
            "value": 200,
        }
    ]
}