import { ElMessage, ElMessageBox } from "element-plus";
import dayJs from "dayjs";
export const dayjs=dayJs

/**
 * 导出文件
 * @param {Blob} blobData
 * @param {String} fileName
 */
export const binaryToExcelExport = (blobData, fileName) => {
    // xlsx   application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
    // xls    application/vnd.ms-excel
    let url = window.URL.createObjectURL(
        new Blob([blobData], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
    ); //data为调完接口获取到的二进制流 type是设置将会被放入到 blob 中的数组内容的 MIME 类型。
    let link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    link.setAttribute("download", fileName); //设置excal名称
    document.body.appendChild(link); //模拟点击事件
    link.click();
    document.body.removeChild(link); //下载完成移除元素
    window.URL.revokeObjectURL(url); //释放掉blob对象
};



/**
 * copy
 * @author Jevon
 * @date 2023-06-07 10:36:31
 * @param { * } text
 */
export const onCopy = (text) => {
    if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(text).then(() => { });
    } else {
        let textArea = document.createElement("textarea");
        textArea.value = text;
        // 使text area不在viewport，同时设置不可见
        textArea.style.position = "absolute";
        textArea.style.opacity = 0;
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
            // 执行复制命令并移除文本框
            document.execCommand("copy") ? res() : rej();
            textArea.remove();
        });
    }
    // ElMessage({
    //   type: "success",
    //   message: '复制成功',
    // });
    return true
};



/**
 * id反查name
 * @param {*} id 匹配id
 * @param {*} list 源数组
 * @param {String} fieldId 字段名如id/Id/ID/channelId
 * @param {String} fieldName 指定返回项
 * @returns
 */
export const idToName = (id, list, fieldId = "value", fieldName = "label") => {
    if (id != null && list) {
      const obj = list.find((item) => {
        return item[fieldId] == id;
      });
      return obj && obj[fieldName];
    } else {
      return "";
    }
  };



  // 小数保留2位浮点数
export const numberTool = (number) => {
  let num = parseFloat(number);
  if (num != 0 && num) {
    return Math.floor(num * 100) / 100;
  } else {
    return "0.00";
  }
};
// or
// export const floatPlaces = (num, n) => { return Math.round(num * Math.pow(10, n)) / Math.pow(10, n); }


/**
 * 精准比较比较时间范围间隔是否大于指定天数
 * @author Jevon
 * @date 2023-11-01 10:53:47
 * @param { Date } startTime
 * @param { Date } endTime
 * @param { Number } day
 */
export const diffTime = (startTime, endTime, day) => {
  let start = new Date(startTime).getTime()
  let end = new Date(endTime).getTime()
  if (start > end) {
    ElMessage({
      message: "开始时间不能大于结束时间！",
      type: "warning",
    });
    return false
  }
  if ((end - start) > (1000 * 60 * 60 * 24 * day)) {
    ElMessage({
      message: `时间跨度超过${day}天！`,
      type: "warning",
    });
    return false
  }
  return true
}
