<template>
  <div style="margin: 30px">
    <el-card shadow="hover"> 余额：{{ money }} 元 </el-card>
  </div>
</template>
<script>
export default {
  name: "my",
};
</script>
<script setup>
import { reactive, onBeforeMount, ref } from "vue";
import { queryBalance } from "@/http/api/queryBalance";
import dayjs from "dayjs";
import { numberTool, idToName, binaryToExcelExport } from "@/utils/tool";
import dic from "@/utils/dictionary";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const money = ref(0);
onBeforeMount(async () => {
  const res = await queryBalance();
  if (res.code == 0) {
    money.value = res.data.balance || 0;
  }
});
</script>
<style lang="scss" scoped>
</style>